import React from 'react'
import styled from 'styled-components'

import * as Colors from '../../../utils/colors'
import LightIcon from './info-light.svg'
import DarkIcon from './info-dark.svg'

const InfoText = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-right: 4rem;

  @media (max-width: 992px) {
    padding-right: 0;
    margin-bottom: 2rem;
  }
`

const Message = styled.span`
  color: ${Colors.GREY};
  font-size: 20px;
  line-height: 28px;
`

const InfoIcon = styled.img`
  margin: 0.25rem 1rem 0 0;
`

export default ({ message, iconColor = 'dark' }) => (
  <InfoText>
    <InfoIcon
      src={iconColor === 'dark' ? DarkIcon : LightIcon}
      alt="Info icon"
    />
    <Message>{message}</Message>
  </InfoText>
)

