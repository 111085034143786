import React from 'react'
import styled from 'styled-components'

import * as Colors from '../../utils/colors'

const ErrorMessage = styled.div`
  color: ${Colors.RED};
`

export default ({ children }) => <ErrorMessage>{children}</ErrorMessage>
