import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import * as Colors from '../../../utils/colors'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8rem;
  padding-bottom: 5rem;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
`

const HeaderText = styled.h1`
  color: ${props => props.color};
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  margin-bottom: 0;

  @media (max-width: 992px) {
    font-size: 40px;
  }
`

const Steps = styled.span`
  font-weight: normal;
  margin-right: 1rem;
`

export default ({ step, maxSteps, clock }) => (
  <Wrapper>
    <HeaderText color={step === 2 ? Colors.LIGHT_GREY : Colors.BLACK}>
      <Steps>{`${step}/${maxSteps}`}</Steps>
      <FormattedMessage id="EXCHANGE.NEW_TRANSACTION" />
    </HeaderText>
  </Wrapper>
)
