import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import * as Colors from '../../utils/colors'
import Input from '../Input'
import ErrorIcon from './error.svg'

const InputGroupWrapper = styled.div`
  flex: 1;
`

const Label = styled.label`
  color: ${Colors.BLACK};
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
`

const FullWidth = styled(Input)`
  text-align: left;
  border-bottom: 1px solid ${Colors.GREY};
  color: ${Colors.BLACK};
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  width: 100%;
  padding-top: 0;

  &:active, &:focus {
    border-bottom: 2px solid ${Colors.GREEN};
  }

  &::placeholder {
    font-weight: normal;
    opacity: 0.3;
  }
`

const Icon = styled.img`
  margin-bottom: 0;
  margin-left: 1rem;
`

const ErrorMessage = styled.div`
  text-align: right;
  color: ${Colors.RED};
  font-size: 20px;
  line-height: 28px;
`

const InputBlock = (props) => (
  <InputGroupWrapper className="form-group">
    <Label htmlFor={props.name}>
      {props.label}
      {
        props.errorMessage && props.errorMessage !== ''
          ? <Icon src={ErrorIcon} />
          : null
      }
    </Label>
    <FullWidth {...props} />
    {
      props.errorMessage && props.errorMessage !== ''
        ? <ErrorMessage><FormattedMessage id={props.errorMessage} /></ErrorMessage>
        : null
    }
  </InputGroupWrapper>
)

export default InputBlock