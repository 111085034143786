import React from 'react'
import { connect } from 'react-redux'

// Redux actions
import {
  initialCoinSet,
  setOriginCoin,
  setTargetCoin,
  changeOriginValue,
  changeTargetValue,
} from '../state/actions/coinValues'

import {
  createTransaction,
  setTransactionEmail,
  setTransactionWallet,
  finishTransaction,
} from '../state/actions/transaction'

// Presentational components
import TradeForm from '../components/Trade/TradeForm'
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SEO from '../components/SEO'

class TradePage extends React.Component {
  componentDidMount() {
    if (!this.props.targetCoin.selected) {
      this.props.initialCoinSet()
    }
  }

  render() {
    const { step } = this.props.transaction
    return (
      <Layout>
        <SEO
          title="GoStable - Stabilize your digital assets within seconds"
          keywords={[
            `btc`,
            `bitcoin`,
            `stablecoin`,
            `stable`,
            `convert`,
            `trade`,
          ]}
        />
        <Navbar showLogoDesktop={true} theme={step === 2 ? 'dark' : 'light'} />
        <TradeForm {...this.props} />
        <Footer />
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    originCoin: state.originCoin,
    targetCoin: state.targetCoin,
    transaction: state.transaction,
  }
}

const mapDispatchToProps = dispatch => ({
  initialCoinSet: () => dispatch(initialCoinSet()),
  setOriginCoin: coin => dispatch(setOriginCoin(coin)),
  setTargetCoin: coin => dispatch(setTargetCoin(coin)),
  changeOriginValue: value => dispatch(changeOriginValue(value)),
  changeTargetValue: value => dispatch(changeTargetValue(value)),
  createTransaction: () => dispatch(createTransaction()),
  setTransactionEmail: ref => dispatch(setTransactionEmail(ref)),
  setTransactionWallet: ref => dispatch(setTransactionWallet(ref)),
  finishTransaction: () => dispatch(finishTransaction()),
})

const ConnectedTradePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(TradePage)

export default ConnectedTradePage
