import React from 'react'
import styled from 'styled-components'
import { FormattedMessage, injectIntl } from 'react-intl'

import InputGroup from '../../InputGroup'
import InfoText from '../InfoText'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 3rem;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`

class Email extends React.Component {
  render() {
    const {
      errorMessage,
      intl,
      onChange,
      value,
    } = this.props
    return (
      <Wrapper>
        <InfoText message={<FormattedMessage id="EXCHANGE.EMAIL_INFO_TEXT" />} />

        <InputGroup
          name="email"
          label={<FormattedMessage id="EXCHANGE.EMAIL_LABEL" />}
          placeholder={intl.formatMessage({ id: "EXCHANGE.EMAIL_PLACEHOLDER" })}
          type="text"
          value={value}
          onChange={ev => onChange(ev)}
          errorMessage={errorMessage}
        />
      </Wrapper>
    )
  }
}

export default injectIntl(Email)
