import React from 'react'
import validator from 'validator'
import { ScrollToHOC } from 'react-scroll-to'

import * as Colors from '../../../utils/colors'
import Container from '../../Container'
import Background from '../../Backgrounds/DefaultWithLogo'

import TradeFormView from './TradeForm-view'

class TradeFormContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      emailErrorMessage: '',
      walletErrorMessage: '',
      agreeTermsError: true,
      agreeTermsErrorMessage: '',
      notUSError: true,
      notUSErrorMessage: '',
    }

    this.startTrade = this.startTrade.bind(this)
  }

  clearValidations() {
    this.setState({
      emailErrorMessage: '',
      walletErrorMessage: '',
      agreeTermsError: true,
      agreeTermsErrorMessage: '',
      notUSError: true,
      notUSErrorMessage: '',
    })
  }

  async startTrade(event) {
    event.preventDefault()

    const { email, wallet } = this.props.transaction
    const { agreeTerms, notUS } = this.state
    let errorCount = 0

    this.clearValidations()

    if (!email || email === '') {
      this.setState({ emailErrorMessage: 'EXCHANGE.FIELD_EMPTY' })
      errorCount++
    }

    if (email && !validator.isEmail(email)) {
      this.setState({ emailErrorMessage: 'EXCHANGE.INVALID_EMAIL' })
      errorCount++
    }

    if (!wallet || wallet === '') {
      this.setState({ walletErrorMessage: 'EXCHANGE.FIELD_EMPTY' })
      errorCount++
    }

    if (!agreeTerms) {
      this.setState({ agreeTermsErrorMessage: 'EXCHANGE.AGREE_TERMS_MISSING' })
      errorCount++
    }

    if (!notUS) {
      this.setState({ notUSErrorMessage: 'EXCHANGE.NOT_US_MISSING' })
      errorCount++
    }

    if (errorCount <= 0) {
      const res = await this.props.createTransaction()
      if (res.type === 'POST_TRANSACTION_SUCCESS') {
        console.log(this.props)
        this.props.scroll({ y: 0, smooth: true })
      }
    }
  }

  render() {
    const { transaction } = this.props
    const { agreeTerms, notUS } = this.state
    return (
      <Background
        color={transaction.step === 2 ? Colors.BLACK : Colors.BACKGROUND}
        useImage={transaction.step !== 2}
      >
        <Container>
          <TradeFormView
            {...this.props}
            {...this.state}
            agreeTermsOnPress={() => this.setState({ agreeTerms: !agreeTerms })}
            notUSOnPress={() => this.setState({ notUS: !notUS })}
            onSubmit={ev => this.startTrade(ev)}
          />
        </Container>
      </Background>
    )
  }
}

export default ScrollToHOC(props => <TradeFormContainer {...props} />)
