import React from 'react'
import styled from 'styled-components'
import { FormattedMessage, injectIntl } from 'react-intl'

import * as Colors from '../../../utils/colors'
import InputCoinSelector from '../../InputCoinSelector'
import SimpleButton from '../../Buttons/SimpleButton'
import InfoText from '../InfoText'
import Checkbox from '../../Checkbox'
import SwitchCoinsButton from '../../Buttons/SwitchCoinsButton'
import Loader from '../../Loader'
import ErrorMessage from '../../ErrorMessage'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 3rem;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`

const RightSideWrapper = styled.div`
  flex: 1;
  position: relative;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  input {
    flex: 2;
  }
`

const CheckboxWrapper = styled.div``

const InputLabel = styled.span`
  color: ${Colors.BLACK};
  font-size: 20px;
  line-height: 28px;
`

const SubmitButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 3rem 0 6rem 0;

  @media (max-width: 992px) {
    justify-content: space-between;
  }
`

const StyledSwitchButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  position: absolute;
  right: 0;

  @media (max-width: 992px) {
    margin-bottom: 0;
  }
`

const StyledSwitchButton = styled(SwitchCoinsButton)`
  margin: 0;
`

class Coins extends React.Component {
  render() {
    const {
      originCoin,
      setOriginCoin,
      changeOriginValue,
      targetCoin,
      setTargetCoin,
      changeTargetValue,
      agreeTerms,
      agreeTermsErrorMessage,
      agreeTermsOnPress,
      notUS,
      notUSErrorMessage,
      notUSOnPress,
      transaction,
    } = this.props
    const { loading, errorMessage } = transaction
    return (
      <Wrapper>
        <InfoText
          message={<FormattedMessage id="EXCHANGE.COINS_INFO_TEXT" />}
        />

        <RightSideWrapper>
          <InputWrapper>
            <InputLabel>
              <FormattedMessage id="EXCHANGE.SEND_LABEL" />
            </InputLabel>

            <InputCoinSelector
              coin={originCoin}
              setCoin={setOriginCoin}
              setValue={changeOriginValue}
              theme="light"
            />
          </InputWrapper>

          <StyledSwitchButtonWrapper>
            <StyledSwitchButton />
          </StyledSwitchButtonWrapper>

          <InputWrapper>
            <InputLabel>
              <FormattedMessage id="EXCHANGE.RECEIVE_LABEL" />
            </InputLabel>

            <InputCoinSelector
              coin={targetCoin}
              setCoin={setTargetCoin}
              setValue={changeTargetValue}
              theme="light"
              symbol={'\u2248'}
            />
          </InputWrapper>

          <CheckboxWrapper>
            <Checkbox
              id="agree-terms"
              checked={agreeTerms}
              errorMessage={agreeTermsErrorMessage}
              onChange={() => agreeTermsOnPress()}
              label={
                <>
                  <FormattedMessage id="EXCHANGE.I_AGREE_WITH_THE" />
                  <b>
                    <FormattedMessage id="EXCHANGE.PRIVACY_POLICY" />
                  </b>
                  <FormattedMessage id="EXCHANGE.AND" />
                  <b>
                    <FormattedMessage id="EXCHANGE.TERMS_OF_SERVICE" />
                  </b>
                </>
              }
            />

            <Checkbox
              id="not-us"
              checked={notUS}
              errorMessage={notUSErrorMessage}
              onChange={() => notUSOnPress()}
              label={
                <>
                  <FormattedMessage id="EXCHANGE.I_AM_NOT_AN" />
                  <b>
                    <FormattedMessage id="EXCHANGE.US_CITIZEN" />
                  </b>
                </>
              }
            />
          </CheckboxWrapper>

          <SubmitButtonWrapper>
            <ErrorMessage>{errorMessage}</ErrorMessage>
            <SimpleButton>
              {loading && <Loader />}
              <FormattedMessage
                id={
                  loading
                    ? 'EXCHANGE.CONFIRMING'
                    : 'EXCHANGE.CONFIRM_TRANSACTION'
                }
              />
            </SimpleButton>
          </SubmitButtonWrapper>
        </RightSideWrapper>
      </Wrapper>
    )
  }
}

export default injectIntl(Coins)
