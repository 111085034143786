import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { navigate } from '@reach/router'

import * as Colors from '../../../utils/colors'
import SimpleButton from '../../Buttons/SimpleButton'

import DepositWallet from '../DepositWallet'
import ReviewInfo from '../ReviewInfo'
import InfoText from '../InfoText'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const SideBySide = styled.div`
  display: flex;
`

const FlexOne = styled.div`
  flex: 1;
`

const InfoWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 6rem;
  border-top: 1px solid ${Colors.GREY};

  @media (max-width: 992px) {
    padding-bottom: 2rem;
    margin-left: 0;
  }
`
const Padding = styled.div`
  flex-direction: column;
  padding-bottom: 1.5rem;
`

const TitleText = styled.h2`
  color: ${Colors.LIGHT_GREY};
  font-weight: normal;
  font-size: 28px;
  line-height: 40px;
  margin-top: 2rem;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  margin-bottom: 8rem;

  @media (max-width: 992px) {
    display: grid;
    justify-content: flex-start;
  }
`

const StyledButton = styled(SimpleButton)`
  margin: 0;
`

const InvertedButton = styled(SimpleButton)`
  margin: 0;
  background: transparent;
  border: 2px solid ${Colors.GREEN};
  color: ${Colors.GREEN};
  margin-right: 2rem;

  @media (max-width: 992px) {
    margin-bottom: 2rem;
    margin-right: 0;
  }
`

export default class TransactionInformation extends React.Component {
  transactionDone(ref) {
    this.props.finishTransaction()
    navigate(`/transaction/${ref}`)
  }

  render() {
    const { transaction } = this.props
    console.log(transaction)
    return (
      <Wrapper>
        <DepositWallet
          wallet={
            transaction.completedTransaction.coinswitchOrder.exchangeAddress
              .address
          }
          coin={transaction.completedTransaction.depositCoin}
          amount={transaction.completedTransaction.depositAmount}
        />

        <SideBySide>
          <FlexOne>
            <InfoWrapper>
              <TitleText>
                <FormattedMessage id="EXCHANGE.REVIEW_INFO_DONT_FORGET" />
              </TitleText>
              <Padding>
                <InfoText
                  message={
                    <FormattedMessage id="EXCHANGE.REVIEW_INFO_NUMBER" />
                  }
                  iconColor={'light'}
                />
              </Padding>
              <InfoText
                message={<FormattedMessage id="EXCHANGE.REVIEW_INFO_TIME" />}
                iconColor={'light'}
              />
            </InfoWrapper>
          </FlexOne>

          <FlexOne>
            <ReviewInfo transaction={transaction.completedTransaction} />
          </FlexOne>
        </SideBySide>

        <ButtonsWrapper>
          <InvertedButton onClick={() => navigate('/')}>
            <FormattedMessage id="EXCHANGE.GO_BACK" />
          </InvertedButton>
          <StyledButton
            onClick={() =>
              this.transactionDone(
                transaction.completedTransaction.coinswitchOrder.orderId
              )
            }
          >
            <FormattedMessage id="EXCHANGE.TRANSACTION_DONE" />
          </StyledButton>
        </ButtonsWrapper>
      </Wrapper>
    )
  }
}
