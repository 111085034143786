import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import * as Colors from '../../utils/colors'
import Unchecked from './unchecked.svg'
import Checked from './checked.svg'
import ErrorMessage from '../ErrorMessage'

const InputGroupWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
`

const Label = styled.label`
  font-size: 20px;
  line-height: 28px;
  cursor: pointer;
  color: ${props => props.checked ? Colors.BLACK : Colors.GREY};
`

const Check = styled.input`
  display:none;

  & + label.checkbox-icon {
    background: url('${Unchecked}');
    min-width: 20px;
    width: 20px;
    height: 20px;
    display:inline-block;
    padding: 0;
    margin-right: 1rem;
    cursor: pointer;
    margin-top: 4px;
  }

  &:checked + label.checkbox-icon {
    background: url('${Checked}');
  }
`

const ErrorMessageWrapper = styled.span`
  display: flex;
  flex-direction: column;
`

const Checkbox = ({ id, label, checked = false, onChange = () => {}, errorMessage }) => (
  <InputGroupWrapper>
		<Check type="checkbox" id={id} checked={checked} onChange={() => onChange()} />
    <label htmlFor={id} className="checkbox-icon" />
    <ErrorMessageWrapper>
      <Label htmlFor={id} checked={checked}>{label}</Label>
      { errorMessage && <ErrorMessage><FormattedMessage id={errorMessage} /></ErrorMessage> }
    </ErrorMessageWrapper>
	</InputGroupWrapper>
)

export default Checkbox