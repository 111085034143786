import React from 'react'

import Coins from '../Coins'
import Email from '../Email'
import Title from '../Title'
import TransactionInformation from '../TransactionInformation'
import Wallet from '../Wallet'

export default class TradeFormView extends React.Component {
  render() {
    const {
      originCoin,
      setOriginCoin,
      changeOriginValue,
      targetCoin,
      setTargetCoin,
      changeTargetValue,
      setTransactionEmail,
      setTransactionWallet,
      transaction,
      agreeTerms,
      notUS,
      emailErrorMessage,
      walletErrorMessage,
      agreeTermsErrorMessage,
      notUSErrorMessage,
      agreeTermsOnPress,
      notUSOnPress,
      onSubmit,
    } = this.props
    return (
      <>
        <Title step={transaction.step} maxSteps={2} />

        {transaction.step === 1 ? (
          <form onSubmit={ev => onSubmit(ev)}>
            <Email
              value={transaction.email}
              onChange={ev => setTransactionEmail(ev.target.value)}
              errorMessage={emailErrorMessage}
            />

            <Wallet
              value={transaction.wallet}
              onChange={ev => setTransactionWallet(ev.target.value)}
              errorMessage={walletErrorMessage}
            />

            <Coins
              originCoin={originCoin}
              setOriginCoin={setOriginCoin}
              changeOriginValue={changeOriginValue}
              targetCoin={targetCoin}
              setTargetCoin={setTargetCoin}
              changeTargetValue={changeTargetValue}
              agreeTerms={agreeTerms}
              agreeTermsErrorMessage={agreeTermsErrorMessage}
              agreeTermsOnPress={() => agreeTermsOnPress()}
              notUS={notUS}
              notUSErrorMessage={notUSErrorMessage}
              notUSOnPress={() => notUSOnPress()}
              transaction={transaction}
            />
          </form>
        ) : (
          <TransactionInformation {...this.props} transaction={transaction} />
        )}
      </>
    )
  }
}
