import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import * as Colors from '../../../utils/colors'
import SimpleButton from '../../Buttons/SimpleButton'
import InfoText from '../InfoText'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 6rem;
  border-top: 1px solid ${Colors.GREY};

  @media (max-width: 992px) {
    padding-bottom: 2rem;
  }
`

const TitleText = styled.h2`
  color: ${Colors.LIGHT_GREY};
  font-weight: normal;
  font-size: 28px;
  line-height: 40px;
  margin-top: 2rem;
  display: flex;
  align-items: center;
`

const WalletText = styled.p`
  color: ${Colors.LIGHT_GREY};
  font-weight: bold;
  font-size: 40px;
  line-height: 56px;
`

const InfoTextWrapper = styled.div`
  display: flex;
`

const Space = styled.div`
  flex: 1;

  @media (max-width: 992px) {
    flex: 0;
  }
`

const CopyAddressButton = styled(SimpleButton)`
  border: 1px solid ${Colors.GREEN};
  padding: 0.25rem 0.5rem;
  background: ${props => (props.copied ? Colors.GREEN : 'transparent')};
  margin: 0;
  margin-left: 2rem;
  font-size: 16px;
  line-height: 20px;
  font-weight: lighter;
  transition: all 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  color: ${props => (props.copied ? Colors.WHITE : Colors.GREEN)};
`

export default class DepositWallet extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      copied: false,
    }
  }

  render() {
    const { copied } = this.state
    const { wallet, coin, amount } = this.props
    return (
      <Wrapper>
        <TitleText>
          <FormattedMessage id="EXCHANGE.SEND_YOUR_FUNDS_TO" />
          <CopyToClipboard
            text={wallet}
            onCopy={() => this.setState({ copied: true })}
          >
            <CopyAddressButton copied={copied}>
              {!copied && <FormattedMessage id="EXCHANGE.COPY_ADDRESS" />}
              {copied && <FormattedMessage id="EXCHANGE.COPIED_TO_CLIPBOARD" />}
            </CopyAddressButton>
          </CopyToClipboard>
        </TitleText>

        <WalletText>{wallet}</WalletText>

        <InfoTextWrapper>
          <InfoText
            message={
              <FormattedMessage id="EXCHANGE.DEPOSIT_WALLET_INFO_TEXT" />
            }
            iconColor={'light'}
          />
          <Space />
        </InfoTextWrapper>

        <TitleText>
          <FormattedMessage id="EXCHANGE.AMOUNT" />
        </TitleText>
        <WalletText>{`${amount} ${coin}`}</WalletText>
      </Wrapper>
    )
  }
}
