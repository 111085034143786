import * as types from '../types'
import backend from '../../utils/backend'

/**
 * Gathers the transaction data from the appropriate reducers and creates a new transaction.
 */
export function createTransaction() {
  return async (dispatch, getState) => {
    try {
      await dispatch({ type: types.POST_TRANSACTION_REQUEST })

      const result = await backend.post('order', {
        email: getState().transaction.email,
        address: getState().transaction.wallet,
        depositAmount: getState().originCoin.value,
        depositCoin: getState().originCoin.selected.key.toLowerCase(),
        destinationCoin: getState().targetCoin.selected.key.toLowerCase(),
      })

      if (!result.data.error) {
        return dispatch({
          type: types.POST_TRANSACTION_SUCCESS,
          tx: result.data,
        })
      } else {
        return dispatch({
          type: types.POST_TRANSACTION_FAILURE,
          message: result.data.error,
        })
      }
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Something went wrong.'

      return dispatch({ type: types.POST_TRANSACTION_FAILURE, message })
    }
  }
}

/**
 * Updates the current value for the transaction email.
 */
export function setTransactionEmail(email) {
  return async dispatch =>
    dispatch({ type: types.SET_TRANSACTION_EMAIL, email })
}

/**
 * Updates the current value for the transaction wallet.
 */
export function setTransactionWallet(wallet) {
  return async dispatch =>
    dispatch({ type: types.SET_TRANSACTION_WALLET, wallet })
}

export function finishTransaction() {
  return async dispatch => dispatch({ type: types.FINISH_TRANSACTION })
}
