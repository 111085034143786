import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import * as Colors from '../../../utils/colors'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 6rem;
  border-top: 1px solid ${Colors.GREY};
  margin-left: 2rem;

  @media (max-width: 992px) {
    padding-bottom: 2rem;
    margin-left: 0;
  }
`

const SideBySide = styled.div`
  display: flex;
`

const FlexOne = styled.div`
  flex: 1;
`

const TitleText = styled.h2`
  color: ${Colors.LIGHT_GREY};
  font-weight: normal;
  font-size: 28px;
  line-height: 40px;
  margin-top: 2rem;
`

const LabelText = styled.p`
  color: ${Colors.GREY};
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 0;
`

const InfoText = styled.p`
  color: ${Colors.LIGHT_GREY};
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 1.5rem;
`

export default class ReviewInfo extends React.Component {
  render() {
    const { transaction } = this.props

    return (
      <Wrapper>
        <TitleText>
          <FormattedMessage id="EXCHANGE.REVIEW_INFO" />
        </TitleText>

        <LabelText>
          <FormattedMessage id="EXCHANGE.YOUR_EMAIL_ADDRESS" />
        </LabelText>
        <InfoText>{transaction.email}</InfoText>

        <SideBySide>
          <FlexOne>
            <LabelText>
              <FormattedMessage id="EXCHANGE.SENDING" />
            </LabelText>
            <InfoText>{`${transaction.depositAmount} ${
              transaction.depositCoin
            }`}</InfoText>
          </FlexOne>

          <FlexOne>
            <LabelText>
              <FormattedMessage id="EXCHANGE.RECEIVING" />
            </LabelText>
            <InfoText>{`${
              transaction.coinswitchOrder.expectedDestinationCoinAmount
            } ${transaction.destinationCoin}`}</InfoText>
          </FlexOne>
        </SideBySide>

        <LabelText>
          <FormattedMessage id="EXCHANGE.YOUR_WALLET_ADDRESS" />
        </LabelText>
        <InfoText>{transaction.address}</InfoText>
      </Wrapper>
    )
  }
}
